import Layout from "@/components/Layout/Layout.vue";
import Main from "@/components/Layout/Main.vue";

export default {
  path: '/user',
  component: Layout,
  name: 'user',
  meta: { title: '用户管理' },
  redirect: '/user/manage/list',
  children: [
    {
      path: '/user/manage',
      meta: {
        keepAlive: true,
        title: '用户管理',
        parentMenu: '/user',
        icon: 'le-icon-xiaochengxu'
      },
	  component: Main,
      children: [
        {
          path: '/user/manage/list',
          component: () => import(/* webpackChunkName: "channel/appletsInfo" */ '../../views/user/manage/list'),
          meta: {
            title: '用户列表',
            parentMenu: '/user',
          }
        }
      ]
    },
  ]
};
