<template>
	<el-form class="le-matter" label-width="110px" ref="form" :model="form" :rules="rules" @submit.native.prevent>
		<el-form-item prop="corp_id">
			<span slot="label" class="le-form-item__label">结算机构</span>
			<el-select v-model="form.payment_id" @change="selectPay" placeholder="请选择结算机构" style="width: 100%;">
				<el-option v-for="item in paymentList" :key="item.value" :label="item.payment_name" :value="item.payment_id">
				</el-option>
			</el-select>
		</el-form-item>
		<el-form-item prop="mchnt_cd">
			<span slot="label" class="le-form-item__label">商户号</span>
			<el-input v-model="form.mchnt_cd" placeholder="请输入综合收单登记的商户号"></el-input>
		</el-form-item>
		<el-form-item prop="shop_id">
			<span slot="label" class="le-form-item__label">店铺号</span>
			<el-input v-model="form.shop_id" placeholder="请输入商户下的店铺号"></el-input>
		</el-form-item>
	</el-form>
</template>

<script>
	export default {
		props: {
			value: {
				type: [Object, Array]
			}
		},
		data() {
			return {
				paymentList: [],
				form: {
					node_id:0,
					mchnt_cd: '',
					shop_id: '',
				},
				rules: {
					mchnt_cd: [{
						required: true,
						message: '请输入商户号',
						trigger: 'blur'
					}],
					shop_id: [{
						required: true,
						message: '请输入店铺号',
						trigger: 'change'
					}],
				}
			};
		},
		methods: {
			affirm: function(e) {
				let {
					handleClose
				} = e;
				console.log(this.form)
				this.$refs['form'].validate(valid => {
					if (valid) {
						this.$http.request({
							url: '/api.admin.node.editacc/',
							method: 'post',
							data: this.form,
						}).then((res) => {
							if (res.code == 200) {
								handleClose(res.data);
								this.$message({
									message: '编辑网点资料信息成功',
									type: 'success'
								});
							}
						})
					} else {
				  return false;
					}
				});
			},
			getPaymentList () {
				this.$http.request({
					url: '/api.admin.node.paymentlist/',
					method: 'post',
					data: {},
				}).then((res) => {
					if(res.code=="200"){
						this.paymentList = res.data
					}
				})
			},
		},
		mounted() {
			this.getPaymentList()
			if (Object.prototype.toString.call(this.value) === '[object Object]') {
				this.$http.request({
					url: '/api.admin.node.detail/',
					method: 'post',
					data: {
						node_id: this.value.node_id
					},
				}).then((res) => {
					if (res.code == 200) {
						this.form = {
							node_id: this.value.node_id,
							mchnt_cd: res.data.mchnt_cd,
							payment_id: res.data.payment_id,
							shop_id: res.data.shop_id,
						}
					}
				})
			}
		}
	};
</script>

<style>
</style>
