<template>
	<el-form class="le-matter" label-width="80px" ref="form" :model="form" :rules="rules" @submit.native.prevent>
		<el-form-item prop="version_code">
			<span slot="label" class="le-form-item__label">版本号</span>
			<el-input-number :step="0.1" controls-position="right" style="width: 100%;" v-model="form.version_code" placeholder="请输入版本号"></el-input-number>
		</el-form-item>
		<el-form-item prop="version_name">
			<span slot="label" class="le-form-item__label">版本名称</span>
			<el-input v-model="form.version_name" placeholder="请输入版本名称"></el-input>
		</el-form-item>
		<el-form-item prop="url">
			<span slot="label" class="le-form-item__label">下载地址</span>
			<el-input v-model="form.url" placeholder="请输入客户端下载地址"></el-input>
		</el-form-item>
	</el-form>
</template>

<script>
	export default {
		props: {
			value: {
				type: [Object, Array]
			}
		},
		data() {
			return {
				form: {
					version_code: '',
					version_name: '',
					url: '',
					app_id: 0
				},
				rules: {
					version_code: [{
						required: true,
						message: '请输入版本号',
						trigger: 'blur'
					}],
					version_name: [{
						required: true,
						message: '请输入版本名称',
						trigger: 'blur'
					}],
					url: [{
						required: true,
						message: '请输入客户端下载地址',
						trigger: 'blur'
					}],
				},
			};
		},
		methods: {
			affirm: function(e) {
				 console.log();
				let {
					handleClose
				} = e;
				this.$refs['form'].validate(valid => {
					if (valid) {
						this.$http.request({
							url: '/api.admin.app.add/',
							method: 'post',
							data: {
								...this.form
							},
							
						}).then((res) => {
							if (res.code == 200) {
								handleClose(res.data);
								this.$message({
									message: this.form.app_id ? '编辑客户端信息成功' : '添加客户端信息成功',
									type: 'success'
								});
							}
						})
					} else {
						return false;
					}
				});
			},
			treeChange (a,b,c) {
				console.log(a,b,c)
			}
		},
		mounted() {
			if (Object.prototype.toString.call(this.value) === '[object Object]') {
				this.form = JSON.parse(JSON.stringify(this.value))
			}
		}
	};
</script>

<style>
</style>
