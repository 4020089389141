<template>
	<el-form v-loading="loading" class="le-matter" label-width="90px" ref="form" :model="form" :rules="rules" @submit.native.prevent>
		<el-form-item prop="corp_id">
			<span slot="label" class="le-form-item__label">所属机构</span>
			<el-select v-model="form.corp_id" @change="selectCorp" placeholder="请选择所属机构" style="width: 100%;">
				<el-option v-for="item in corpList" :key="item.value" :label="item.corp_name" :value="item.corp_id">
				</el-option>
			</el-select>
		</el-form-item>
		<el-form-item prop="node_id">
			<span slot="label" class="le-form-item__label">所属网点</span>
			<el-select v-model="form.node_id" :disabled="form.corp_id == null" :placeholder="form.corp_id == null ? '请先选择网点所属机构' : '请选择所属网点'" style="width: 100%;">
				<el-option v-for="item in nodeList" :key="item.value" :label="item.node_name" :value="item.node_id">
				</el-option>
			</el-select>
		</el-form-item>
		<el-form-item prop="name">
			<span slot="label" class="le-form-item__label">设备名称</span>
			<el-input v-model="form.name" placeholder="请输入设备名称"></el-input>
		</el-form-item>
		<el-form-item prop="address">
			<span slot="label" class="le-form-item__label">设备地址</span>
			<el-input v-model="form.address" placeholder="请输入设备地址"></el-input>
		</el-form-item>
		<el-form-item>
			<span slot="label" class="le-form-item__label">AppID</span>
			<el-input :value="fm.app_id" disabled="disabled" placeholder="请输入设备名称"></el-input>
		</el-form-item>
		<el-form-item>
			<span slot="label" class="le-form-item__label">Secret</span>
			<el-input :value="fm.app_secret" disabled="disabled" placeholder="请输入设备地址"></el-input>
		</el-form-item>
	</el-form>
</template>

<script>
	export default {
		props: {
			value: {
				type: [Object, Array]
			}
		},
		data() {
			return {
				form: {
					id:0,
					corp_id: null,
					node_id: null,
					name: '',
					address: ''
				},
				fm: {
					app_id: '',
					app_secret: '',
				},
				rules: {
					name: [{
						required: true,
						message: '请输入设备名称',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '请输入设备地址',
						trigger: 'blur'
					}],
					corp_id: [{
						required: true,
						message: '请选择所属机构',
						trigger: 'change'
					}],
					node_id: [{
						required: true,
						message: '请选择所属网点',
						trigger: 'change'
					}]
				},
				areaValue3: [],
				corpList: [],
				nodeList: [],
				loading: false
			};
		},
		methods: {
			affirm: function(e) {
				let {
					handleClose
				} = e;
				console.log(this.form)
				this.$refs['form'].validate(valid => {
					if (valid) {
						this.$http.request({
							url: '/api.admin.device.add/',
							method: 'post',
							data: this.form,
						}).then((res) => {
							if (res.code == 200) {
								handleClose(res.data);
								this.$message({
									message: this.form.corp_id ? '编辑设备信息成功' : '添加设备成功',
									type: 'success'
								});
							}
						})
					} else {
				  return false;
					}
				});
			},
			getCorpList () {
				this.$http.request({
					url: '/api.admin.corp.treelist/',
					method: 'post',
					data: {},
				}).then((res) => {
					this.corpList = res.data
				})
			},
			getNodeList () {
				this.$http.request({
					url: '/api.admin.node.treelist/',
					method: 'post',
					data: {
						corp_id: this.form.corp_id
					},
				}).then((res) => {
					this.nodeList = res.data
				})
			},
			selectCorp () {
				this.form.node_id = null
				this.getNodeList()
			}
		},
		mounted() {
			this.getCorpList()
			console.log(this.value)
			if (Object.prototype.toString.call(this.value) === '[object Object]') {
				this.loading = true
				this.$http.request({
					url: '/api.admin.device.detail/',
					method: 'post',
					data: {
						id: this.value.id,
					},
				}).then((res) => {
					this.form = {
						node_id: res.data.node_id,
						corp_id: res.data.corp_id,
						id: this.value.id,
						name: this.value.name,
						address: this.value.address,
					}
					this.fm = {
						app_id: res.data.app_id,
						app_secret: res.data.app_secret
					}
					this.getNodeList()
					this.loading = false
				})
			}
		}
	};
</script>

<style>
</style>
