<template>
	<div v-loading="loading">
		<div id="text" style="width: 800px; height: 400px; position: relative; z-index: 1"></div>
	</div>
</template>

<script>
	// 富文本
	import E from "wangeditor";
	import OSS from "ali-oss";
	import uploadImage from "@/components/uploadImage.vue";
	function random_string(len) {
	  len = len || 32;
	  var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
	  var maxPos = chars.length;
	  var pwd = "";
	  for (let i = 0; i < len; i++) {
	    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
	  }
	  return pwd;
	}
	function getNowFormatDate() {
	      var date = new Date();
	      var seperator1 = "-";
	      var year = date.getFullYear();
	      var month = date.getMonth() + 1;
	      var strDate = date.getDate();
	      if (month >= 1 && month <= 9) {
	        month = "0" + month;
	      }
	      if (strDate >= 0 && strDate <= 9) {
	        strDate = "0" + strDate;
	      }
	      var currentdate = year + seperator1 + month + seperator1 + strDate;
	      return currentdate;
	}
	export default {
		props: {
			value: {
				type: [Object, Array]
			}
		},
		data() {
			return {
				form: {
					node_id:0,
					description: '',
					wx_mchid: '',
					ali_mchid: '',
					brno: '',
					channel_mchid: '',
				},
				loading: true
			};
		},
		components:{
			uploadImage
		},
		methods: {
			affirm: function(e) {
				let {
					handleClose
				} = e;
				this.$http.request({
					url: '/api.admin.node.editdes/',
					method: 'post',
					data: {
						...this.form,
						description: this.editor.txt.html() || "",
					},
				}).then((res) => {
					if (res.code == 200) {
						handleClose(res.data);
						this.$message({
							message: '编辑网点介绍信息成功',
							type: 'success'
						});
					}
				})
			},
			setWebText() {
			  setTimeout(() => {
			    this.editor = new E(document.getElementById("text"));
			    // 配置 server 接口地址
			    // api/file/upload
			    // editor.config.uploadVideoServer = '/api/upload-video'
			    this.editor.config.customUploadImg = function (
			      resultFiles,
			      insertImgFn
			    ) {
			      // resultFiles 是 input 中选中的文件列表
			      // insertImgFn 是获取图片 url 后，插入到编辑器的方法
			
			      // 上传图片，返回结果，将图片插入到编辑器中
				  
				  
				  console.log(event, 'eventevent')
				  let fileData = resultFiles[0];
				  console.log(fileData, 'fileDatafileData')
				  
				  var client = new OSS({
				      endpoint: "oss-cn-beijing.aliyuncs.com",
				      accessKeyId: "LTAI0iCr7zUH4gT1",
				      accessKeySecret: "pwkRwLDvQnXaEavBnOxtb7Im8oRBQ5",
				      bucket: "newbat",
				    });
				    let name = ''
				    if (fileData.name.indexOf(".mp4") >= 0) {
				      name = `video/${getNowFormatDate()}/${random_string(24)}_${fileData.name}`;
				    } else {
				      name = `image/${getNowFormatDate()}/${random_string(24)}_${fileData.name}`;
				    }
				    let self = this
				    client
				      .multipartUpload(name, fileData)
				      .then(function (res) {
				  		insertImgFn(res.res.requestUrls[0]);
				      })
				      .catch((err) => {
				        console.log(err);
				      });
				  
			     
			    };
			
			    this.editor.create();
			    this.editor.txt.html(this.form.description);
				this.loading = false
			  }, 1000);
			},
		},
		mounted() {
			if (Object.prototype.toString.call(this.value) === '[object Object]') {
				this.$http.request({
					url: '/api.admin.node.detail/',
					method: 'post',
					data: {
						node_id: this.value.node_id
					},
				}).then((res) => {
					if (res.code == 200) {
						this.form = {
							node_id: this.value.node_id,
							description: res.data.description || '',
							wx_mchid: res.data.wx_mchid,
							ali_mchid: res.data.ali_mchid,
							brno: res.data.brno,
							channel_mchid: res.data.channel_mchid,
						}
						this.setWebText()
					}
				})
			}
		}
	};
</script>

<style>
</style>
