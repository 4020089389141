<template>
	<el-form class="le-matter" label-width="80px" ref="form" :model="form" :rules="rules" @submit.native.prevent>
		<el-form-item prop="corp_name">
		  <span slot="label" class="le-form-item__label">机构名称</span>
		  <el-input v-model="form.corp_name"  placeholder="请输入机构名称"></el-input>
		</el-form-item>
		<el-form-item prop="province">
		  <span slot="label" class="le-form-item__label">所属地区</span>
		  <area-select @getAara="getAara" :key="areaValue3.length" :area="areaValue3"></area-select>
		</el-form-item>
		<el-form-item prop="address">
		  <span slot="label" class="le-form-item__label">详细地址</span>
		  <el-input v-model="form.address" placeholder="请输入详细地址"></el-input>
		</el-form-item>
		<el-form-item prop="real_name">
		  <span slot="label" class="le-form-item__label">联系人</span>
		  <el-input v-model="form.real_name" placeholder="请输入联系人"></el-input>
		</el-form-item>
		<el-form-item prop="contact">
		  <span slot="label" class="le-form-item__label">联系方式</span>
		  <el-input v-model="form.contact" placeholder="请输入联系方式"></el-input>
		</el-form-item>
	</el-form>
</template>

<script>
	import AreaSelect from "@/components/City1/index.vue";
	export default {
		props: {
			value: {
				type: [Object, Array]
			}
		},
		data() {
			return {
				form: {
					corp_name: '',
					address: '',
					real_name: '',
					contact: '',
					province: '',
					city: '',
					district: '',
					corp_id: 0
				},
				rules: {
					corp_name: [{ required: true, message: '请输入机构名称', trigger: 'blur' }],
					address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
					real_name: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
					contact: [{ required: true, message: '请输入联系方式', trigger: 'blur' }, {
						pattern: /^((0\d{2,3}-\d{7,8})|(1[3657894]\d{9}))$/,
						message: "请输入合法手机号/电话号",
						trigger: "blur"
					}],
					province: [{ required: true, message: '请选择地区', trigger: 'change' }]
				},
				areaValue3: []
			};
		},
		components: {
			AreaSelect
		},
		methods: {
			affirm: function(e) {
				let {
					handleClose
				} = e;
				console.log(this.form)
				this.$refs['form'].validate(valid => {
				  if (valid) {
					this.$http.request({
						url: '/api.admin.corp.add/',
						method: 'post',
						data: this.form,
					}).then((res) => {
						if (res.code == 200) {
							handleClose(res.data);
							this.$message({
							    message: this.form.corp_id ? '编辑机构信息成功' : '添加机构成功',
							    type: 'success'
							});
						}
					})
				  } else {
				    return false;
				  }
				});
			},
			getAara (data) {
				this.form.province = data[0]
				this.form.city = data[1]
				this.form.district = data[2]
			}
		},
		mounted() {
		  if (Object.prototype.toString.call(this.value) === '[object Object]') {
			this.form = {...this.value}
			this.areaValue3 = [this.form.province, this.form.city, this.form.district]
		  }
		}
	};
</script>

<style>
</style>
