<template>
	<el-form class="le-matter" label-width="120px" ref="form" :model="form" :rules="rules" @submit.native.prevent>
		<el-form-item prop="title">
			<span slot="label" class="le-form-item__label">文章标题</span>
			<el-input v-model="form.title" placeholder="请输入文章标题"></el-input>
		</el-form-item>
		<el-form-item prop="thumbnail">
			<span slot="label" class="le-form-item__label">缩略图</span>
			<upload-image v-model="form.thumbnail" :limit="1"></upload-image>
		</el-form-item>
		
		<el-form-item prop="is_used">
			<span slot="label" class="le-form-item__label">状态</span>
			<el-radio-group v-model="form.is_used">
			    <el-radio :label="1">启动</el-radio>
			    <el-radio :label="2">暂停</el-radio>
			  </el-radio-group>
		</el-form-item>
		<el-form-item prop="thumbnail">
			<span slot="label" class="le-form-item__label">文章内容</span>
			<div id="text" style="width: 800px; height: 400px; position: relative; z-index: 1"></div>
		</el-form-item>
	</el-form>
</template>

<script>
	// 富文本
	import E from "wangeditor";
	import OSS from "ali-oss";
	import uploadImage from "@/components/uploadImage.vue";
	function random_string(len) {
	  len = len || 32;
	  var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
	  var maxPos = chars.length;
	  var pwd = "";
	  for (let i = 0; i < len; i++) {
	    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
	  }
	  return pwd;
	}
	function getNowFormatDate() {
	      var date = new Date();
	      var seperator1 = "-";
	      var year = date.getFullYear();
	      var month = date.getMonth() + 1;
	      var strDate = date.getDate();
	      if (month >= 1 && month <= 9) {
	        month = "0" + month;
	      }
	      if (strDate >= 0 && strDate <= 9) {
	        strDate = "0" + strDate;
	      }
	      var currentdate = year + seperator1 + month + seperator1 + strDate;
	      return currentdate;
	}
	export default {
		props: {
			value: {
				type: [Object, Array]
			}
		},
		data() {
			return {
				form: {
					cont_id: 0,
					node_id: 0,
					title: '',
					thumbnail: '',
					content: '',
					is_used: 1,
				},
				rules: {
					thumbnail: [{
						required: true,
						message: '请上传多媒体',
						trigger: 'change'
					}],
				},
			};
		},
		components:{
			uploadImage
		},
		methods: {
			affirm: function(e) {
				let {
					handleClose
				} = e;
				console.log(this.form)
				this.$refs['form'].validate(valid => {
					if (valid) {
						this.$http.request({
							url: '/api.admin.content.add/',
							method: 'post',
							data: {
								...this.form,
								content: this.editor.txt.html() || "",
							},
						}).then((res) => {
							if (res.code == 200) {
								handleClose(res.data);
								this.$message({
									message: this.form.cont_id ? '编辑资讯信息成功' : '添加资讯成功',
									type: 'success'
								});
							}
						})
					} else {
				  return false;
					}
				});
			},
			setWebText() {
			  setTimeout(() => {
			    this.editor = new E(document.getElementById("text"));
			    // 配置 server 接口地址
			    // api/file/upload
			    // editor.config.uploadVideoServer = '/api/upload-video'
			    this.editor.config.customUploadImg = function (
			      resultFiles,
			      insertImgFn
			    ) {
			      // resultFiles 是 input 中选中的文件列表
			      // insertImgFn 是获取图片 url 后，插入到编辑器的方法
			
			      // 上传图片，返回结果，将图片插入到编辑器中
				  
				  
				  console.log(event, 'eventevent')
				  let fileData = resultFiles[0];
				  console.log(fileData, 'fileDatafileData')
				  
				  var client = new OSS({
				      endpoint: "oss-cn-beijing.aliyuncs.com",
				      accessKeyId: "LTAI0iCr7zUH4gT1",
				      accessKeySecret: "pwkRwLDvQnXaEavBnOxtb7Im8oRBQ5",
				      bucket: "newbat",
				    });
				    let name = ''
				    if (fileData.name.indexOf(".mp4") >= 0) {
				      name = `video/${getNowFormatDate()}/${random_string(24)}_${fileData.name}`;
				    } else {
				      name = `image/${getNowFormatDate()}/${random_string(24)}_${fileData.name}`;
				    }
				    let self = this
				    client
				      .multipartUpload(name, fileData)
				      .then(function (res) {
				  		insertImgFn(res.res.requestUrls[0]);
				      })
				      .catch((err) => {
				        console.log(err);
				      });
				  
			     
			    };
			
			    this.editor.create();
				console.log(this.form.content, 'this.form.content')
			    this.editor.txt.html(this.form.content);
			  }, 1000);
			},
		},
		mounted() {
			if (Object.prototype.toString.call(this.value) === '[object Object]') {
				console.log(this.value)
				if (this.value.cont_id) {
					this.$http.request({
						url: '/api.admin.content.detail/',
						method: 'post',
						data: {
							cont_id: this.value.cont_id
						},
					}).then((res) => {
						if (res.code == 200) {
							this.form = {
								cont_id: this.value.cont_id,
								node_id: this.value.node_id,
								title: res.data.title,
								thumbnail: this.value.thumbnail,
								content: res.data.content,
								is_used: res.data.is_used,
							}
							this.setWebText()
						}
					})
				} else {
					this.setWebText()
				}
				this.form.node_id = this.value.node_id
			}
		}
	};
</script>

<style>
</style>
