import Vue from 'vue';

// 添加机构
import mechanism from './views/web/mechanism';
Vue.use(mechanism);

// 添加网点
import node from './views/web/node';
Vue.use(node);

// 添加设备
import device from './views/web/device';
Vue.use(device);

// 添加角色
import role from './views/web/system/role';
Vue.use(role);

// 添加管理员
import admins from './views/web/system/admins';
Vue.use(admins);

// 添加客户端
import dev from './views/web/system/dev';
Vue.use(dev);
