// 年级类型：1初中，2高中-默认1
const gradeType = localStorage.getItem('gradeType') || 1
if (!localStorage.getItem('gradeType')) {
	localStorage.setItem('gradeType', gradeType)
}

export default {
	namespaced: true,
	state: {
		// 年级类型：1初中，2高中-默认1
		gradeType: gradeType
	},
	mutations: {
		SET: (state, obj) => {
			state[obj.key] = obj.value;
		}
	},
	actions: {
		/* 设置节点权限 */
		setGradeType({
			commit
		}, data) {
			localStorage.setItem('gradeType', data)
			commit('SET', {
				key: 'gradeType',
				value: data
			})
		},
	}
}