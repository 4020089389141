/**
 * 项目统一配置
 */
export default {
    version: '1.0',
    name: '后台管理',  // 项目名称
	baseURL: 'https://donate.jinrui.cc/v1',  //' 接口地址
	loginPath: '/login', // 登录路径
	isWhite: true, // 是否开启路由白名单
    whiteList: ['/login'],  // 路由白名单(不需要登录的)
    tokenHeaderName: 'token',  // token传递的header名称
    tokenStoreName: 'token',  // token存储的名称
    userStoreName: 'user',  // 用户信息存储的名称
}