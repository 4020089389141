<template>
	<el-form class="le-matter" label-width="120px" ref="form" :model="form" :rules="rules" @submit.native.prevent>
		<el-form-item prop="type">
			<span slot="label" class="le-form-item__label">多媒体类型</span>
			<el-radio-group v-model="form.type">
			    <el-radio :label="1">图片</el-radio>
			    <el-radio :label="2">视频</el-radio>
			  </el-radio-group>
		</el-form-item>
		<el-form-item prop="url">
			<span slot="label" class="le-form-item__label">多媒体上传</span>
			<upload-image v-model="form.url" :limit="1"></upload-image>
		</el-form-item>
		<el-form-item prop="status">
			<span slot="label" class="le-form-item__label">状态</span>
			<el-radio-group v-model="form.status">
			    <el-radio :label="1">启动</el-radio>
			    <el-radio :label="2">暂停</el-radio>
			  </el-radio-group>
		</el-form-item>
	</el-form>
</template>

<script>
	import uploadImage from "@/components/uploadImage.vue";
	export default {
		props: {
			value: {
				type: [Object, Array]
			}
		},
		data() {
			return {
				form: {
					media_id: 0,
					node_id: 0,
					type: 1,
					url: '',
					status: 1,
				},
				rules: {
					url: [{
						required: true,
						message: '请上传多媒体',
						trigger: 'change'
					}],
				},
			};
		},
		components:{
			uploadImage
		},
		methods: {
			affirm: function(e) {
				let {
					handleClose
				} = e;
				console.log(this.form)
				this.$refs['form'].validate(valid => {
					if (valid) {
						this.$http.request({
							url: '/api.admin.media.add/',
							method: 'post',
							data: this.form,
						}).then((res) => {
							if (res.code == 200) {
								handleClose(res.data);
								this.$message({
									message: this.form.corp_id ? '编辑多媒体信息成功' : '添加多媒体成功',
									type: 'success'
								});
							}
						})
					} else {
				  return false;
					}
				});
			},
		},
		mounted() {
			if (Object.prototype.toString.call(this.value) === '[object Object]') {
				if (this.value.url) {
					this.form = {
						media_id: this.value.media_id,
						node_id: this.value.node_id,
						type: this.value.type,
						url: this.value.url,
						status: this.value.status,
					}
				}
				this.form.node_id = this.value.node_id
			}
		}
	};
</script>

<style>
</style>
