// src/directives/permission.js 
import Vue from 'vue';
import store from '@/store';
// 是否有权限 
const hasPermission = userPermission => {
	let userPermissionList = Array.isArray(userPermission) ? userPermission : [userPermission];
	// 当前用户的权限列表 
	let permissionList = store.state.user.permission;
	return userPermissionList.some(e => permissionList.includes(e));
};
// 指令 
Vue.directive('per', {
	bind: (el, binding, vnode) => {
		if (!hasPermission(binding.value)) {
			el.style.display = 'none'
		}
	}
}); 

// 全局判断方法 
Vue.prototype.$_has = hasPermission;
