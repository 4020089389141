<template>
	<div style="display: flex;width: 100%;">
		<el-cascader
		style="width: 100%;"
		    v-model="areaValue"
		    :options="areaData" @change="getAreaData" :props="{label: 'name', value: 'name'}" placeholder="请选择所属地区"></el-cascader>
	</div>
</template>
<script>
	import areaData from "./areadata";
	export default {
		name: "areaSelect",
		props: ['area'],
		data() {
			return {
				//地区数据
				areaData,
				areaValue: []
			};
		},
		mounted() {
			this.areaValue = [...this.area]
		},
		methods: {
			getAreaData() {
				this.$emit('getAara', this.areaValue)
			}
		},
	};
</script>
<style lang="scss" scoped>
</style>
