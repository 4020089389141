/**
 * axios配置
 */
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from '@/store'
import router from '@/router'
import setting from './setting'
import {MessageBox} from 'element-ui'

Vue.use(VueAxios, axios);

axios.defaults.baseURL = setting.baseURL;
axios.defaults.headers.common[setting.tokenHeaderName] = localStorage.getItem(setting.tokenHeaderName);
/**
 * 请求拦截
 */
axios.interceptors.request.use((config) => {
    let token = localStorage.getItem(setting.tokenHeaderName);
    if (token){
        config.headers[setting.tokenHeaderName] = token;
    }
    return config;
},(err) => {
    return Promise.reject(err);
})
/* 响应拦截器 */
axios.interceptors.response.use((res) => {
    let token = res.headers.authorization;
    if (token){
		localStorage.setItem(setting.tokenHeaderName, token);
    }

    let code = res.data.code;
	console.log(res.data, 'res.code;res.code;')
    switch (code) {
        case 200102:
        case '200':
            return res.data;
        case '201':
			if (res.data.message == "token error") {
				localStorage.removeItem('token')
				localStorage.removeItem('Authorization')
				router.push('/login')
			}
            return Promise.reject(new Error(res.data.message));
        default:
			if (res.data.message && code != 200) {
				Vue.prototype.$message.error(res.data.message);
			}
			if (res.data.data && Array.isArray(res.data.data)) {
				return res.data;
			}
            return res.data;
    }
}, (error) => {
    return Promise.reject(error);
});
