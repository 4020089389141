/*
 * @Author: qinuoyun
 * @Date:   2020-09-08 10:12:19
 * @Last Modified by:   qinuoyun
 * @Last Modified time: 2021-06-21 14:40:12
 */
import webInstruction from '@/instruction/index.js';
//加载页面
import roleAdd from './components/role-add';
import permission from './components/permission';
//初始化变量
let modules = {};
modules.install = function () {
  //添加设置界面
  webInstruction.component('role', 'roleAdd', roleAdd);
  webInstruction.component('role', 'permission', permission);
};
export default modules;
