<template>
	<div class="data-filter">
		<el-form  class="ele-form-search" @keyup.enter.native="filterInquire" @submit.native.prevent>
			<el-row :gutter="15">
				<el-col :md="5" :sm="12" v-for="(item, index) in config" :key="index">
					<el-form-item :label-width="(item.name.length * 19) + 'px'" :label="item.name + ' :'">
						<el-input v-model="where[item.key]" :placeholder="item.placeholder"></el-input>
					</el-form-item>
				</el-col>
				<el-col :md="5" :sm="12">
					<div class="ele-form-actions">
						<el-button type="primary" @click="filterInquire" icon="el-icon-search" class="ele-btn-icon">查询
						</el-button>
						<el-button @click="resetInquire">重置</el-button>
					</div>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script>
	export default {
		name: "EleDataFilter",
		props: {
			config: { // 数据表格参数配置
				type: Array,
				default () {
					return []
				}
			},
			where: {
				type: Object,
				default () {
					return {}
				}
			}
		},
		data() {
			return {
				// 重置数据
				lodWhereData: {}
			}
		},
		mounted() {
			this.lodWhereData = JSON.parse(JSON.stringify(this.where))
		},
		methods: {
			// 过滤查询
			filterInquire() {
				let whereData = this.where
				if (whereData.pageIndex) {
					whereData.pageIndex = 1
				}
				this.$emit('filterInquire', whereData)
			},
			// 重置查询
			resetInquire() {
				let where = JSON.parse(JSON.stringify(this.lodWhereData))
				this.$emit('filterInquire', where)
			}
		}
	}
</script>

<style lang="less" scoped>
	.data-filter {
		padding: 20px;
		background: #fff;
		margin-bottom: 16px;
		border-radius: 4px;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
		padding-bottom: 0px;
	}
</style>
