<template>
	<el-form class="le-matter" label-width="80px" ref="form" :model="form" :rules="rules" @submit.native.prevent>
		<el-form-item prop="role_name">
			<span slot="label" class="le-form-item__label">角色名称</span>
			<el-input v-model="form.role_name" placeholder="请输入角色名称"></el-input>
		</el-form-item>
		<el-form-item prop="role_name">
			<span slot="label" class="le-form-item__label">权限选择</span>
			<div style="margin-top: 10px;">
				<el-tree :data="data" @check-change="treeChange" show-checkbox default-expand-all node-key="id" ref="tree" highlight-current
					:props="defaultProps">
				</el-tree>
			</div>
		</el-form-item>
		<el-form-item prop="is_used">
			<span slot="label" class="le-form-item__label">状态</span>
			<el-radio-group v-model="form.is_used">
				<el-radio :label="1">启用</el-radio>
				<el-radio :label="0">暂停</el-radio>
			</el-radio-group>
		</el-form-item>
	</el-form>
</template>

<script>
	export default {
		props: {
			value: {
				type: [Object, Array]
			}
		},
		data() {
			return {
				data: [{
					id: 1,
					label: '工作台'
				}, {
					id: 4,
					label: '设备管理'
				}, {
					id: 5,
					label: '交易管理',
					children: [{
						id: 6,
						label: '交易明细'
					}, {
						id: 7,
						label: '交易汇总'
					}]
				}, {
					id: 3,
					label: '网点管理'
				}, {
					id: 2,
					label: '机构管理'
				}, {
					id: 8,
					label: '系统管理',
					children: [{
						id: 11,
						label: '管理员列表'
					}, {
						id: 12,
						label: '角色列表'
					}, {
						id: 10,
						label: '资料维护'
					},{
						id: 13,
						label: '客户端管理'
					},{
						id: 9,
						label: '参数配置'
					}]
				}],
				defaultProps: {
					children: 'children',
					label: 'label'
				},
				form: {
					role_name: '', //	是	string	角色名称
					authority: '1', //	是	string	以英文逗号隔开
					is_used: 1, //是	int	1启用0暂停
					role_id: 0
				},
				rules: {
					role_name: [{
						required: true,
						message: '请输入角色名称',
						trigger: 'blur'
					}],
				},
			};
		},
		methods: {
			affirm: function(e) {
				 console.log();
				let {
					handleClose
				} = e;
				this.$refs['form'].validate(valid => {
					if (valid) {
						let d = this.$refs.tree.getCheckedNodes().map((v) => {
									return v.id
						})
						if (d.includes(6) || d.includes(7)) {
							if (!d.includes(5)) {
								d.push(5)
							}
						}
						console.log(d, d.includes(9) || d.includes(10) || d.includes(11) || d.includes(12) ,'d.includes(9) || d.includes(10) || d.includes(11) || d.includes(12)')
						if (d.includes(9) || d.includes(10) || d.includes(11) || d.includes(12)) {
							if (!d.includes(8)) {
								d.push(8)
							}
						}
						let a = d.join()
						this.$http.request({
							url: '/api.admin.role.add/',
							method: 'post',
							data: {
								...this.form,
								authority: a
							},
							
						}).then((res) => {
							if (res.code == 200) {
								handleClose(res.data);
								this.$message({
									message: this.form.role_id ? '编辑角色信息成功' : '添加角色成功',
									type: 'success'
								});
							}
						})
					} else {
						return false;
					}
				});
			},
			treeChange (a,b,c) {
				console.log(a,b,c)
			}
		},
		mounted() {
			if (Object.prototype.toString.call(this.value) === '[object Object]') {
				this.$http.request({
					url: '/api.admin.role.detail/',
					method: 'post',
					data: {
						'role_id': this.value.role_id
					},
				}).then((res) => {
					if (res.code == 200) {
						this.form = {
							role_name: this.value.role_name, //	是	string	角色名称
							is_used: this.value.is_used, //是	int	1启用0暂停
							role_id: this.value.role_id
						}
						this.$refs.tree.setCheckedKeys(res.data.authority.map((v) => {return parseInt(v)}));
					}
				})
			}
		}
	};
</script>

<style>
</style>
