<template>
	<el-container style="height: 100vh;">
		<el-aside :width="!collapse ? '200px' : '63px'" style="background-color: rgb(238, 241, 246);height: 100vh;overflow: hidden;">
			<div class="header-logo" style="padding-left: 5px;">
				 {{!collapse ? sys_name : '' }}
				<img :class="{'collapsess' : collapse}" src="../../assets/css.png" @click="() => {collapse = !collapse}">
			</div>
			
			<el-menu class="elmenuleft scrollbar-css" :router="true" :key="sidebarKey" :default-active="sidebarActive" :unique-opened="true" :collapse="collapse">
				<template v-for="(item, index) in sidebarData">
					<el-submenu v-if="isShowEn(item)" :index="item.path">
						<template slot="title"><i :class="item.meta.icon"></i>{{item.meta.title}}</template>
						<el-menu-item v-if="isShowEn1(data)" :index="data.path" v-for="(data, index) in item.children">
							<div class="el-menu-item-div">
								<span></span>{{data.meta.title}}
							</div>
						</el-menu-item>
					</el-submenu>
					<template v-if="isShowEn1(item)">
						<el-menu-item :index="item.path">
							<div class="el-menu-item-level">
								<i :class="item.meta.icon"></i>{{item.meta.title}}
							</div>
						</el-menu-item>
					</template>
				</template>
			</el-menu>
		</el-aside>

		<el-container>
			<el-header class="page-header">
				<div class="header-left">
					<!-- <div class="header-navs" >
						<div @click="headerNavClick(item)" :class="{'active': headerActiveMenu == item.path}" v-for="(item, index) in headerRoutes">{{item.meta.title}}</div>
					</div> -->
					<!-- <div class="silble-hide-btn">
						<img src="../../assets/menu-left.png" >
						<div class="show-hide-title">隐藏左侧菜单</div>
					</div> -->
				</div>
				<div class="header-right">
					<div style="align-items: center;color: rgb(255, 255, 255);display: flex;cursor: pointer;font-size: 14px;"@click="clickDown">
						<img src="../../assets/dldl.png" style="width: 16px;margin-right: 9px;" >
						登出
					</div>
				</div>
			</el-header>

			<el-main id="content-main" style="background: #F8F8F8;height: calc(100% - 60px);">
				<router-view :key="pageKey" />
			</el-main>
		</el-container>
	</el-container>
</template>
<script>
	import '@/pages.js';
	export default {
		data() {
			return {
				sidebarKey: '',
				pageKey: Math.random(),
				authority: JSON.parse(localStorage.getItem('authority')).map((v) => {return parseInt(v)}),
				sys_name: localStorage.getItem('sys_name') || '',
				collapse: false
			};
		},
		computed: {
		  // 底部导航
		  headerRoutes() {
		    return this.$router.options.routes[0].children;
		  },
		  // 头部导航
		  headerActiveMenu() {
		    const route = this.$route;
		    const { meta, path } = route;
		    if (meta.parentMenu) {
		      return meta.parentMenu;
		    }
		    if (meta.activeMenu) {
		      return meta.activeMenu;
		    }
		    return path;
		  },
		  // 侧边栏
		  sidebarData () {
			  const route = this.$route;
			  const { meta, path } = route;
			  let parentMenu = meta.parentMenu
			  let navs = []
			  this.$router.options.routes[0].children.forEach((v) => {
				  if (v.path == parentMenu) {
					 navs = v.children 
				  }
			  })
			  return navs;
		  },
		  sidebarActive () {
			  const route = this.$route;
			  const { meta, path } = route;
			  return path
		  },
		 
		},
		mounted() {
			
		},
		methods: {
			isShowEn (data) {
				if (data.children) {
					if (this.authority.includes(data.meta.id)) {
						return true
					}
					return false
				}
				return false
			},
			isShowEn1 (data) {
				console.log(this.authority, data, 'authority')
				if (data.children) {
					return false
				}
				if (this.authority.includes(data.meta.id)) {
					return true
				}
				return false
			},
			// 头部导航点击
			headerNavClick (e) {
				this.$router.push({
					path: e.redirect
				})
				setTimeout(() => {
					this.sidebarKey = Math.random()
				}, 100)
			},
			clickDown () {
				localStorage.removeItem(this.$_W.tokenHeaderName)
				this.$router.push(this.$_W.loginPath)
			}
		},
	};
</script>
<style lang="less">
	.collapsess {
		    transform: translate(0, -50%) rotate(-180deg) !important;
			right: 20px !important;
	}
	.init-tabs {
		display: flex;
		    align-items: center;
		    background-color: #fff;
		    justify-content: center;
		    padding-top: 20px;
		span {
			display: flex;
			width: 90px;
			height: 38px;
			background: linear-gradient(120deg, rgba(165, 193, 255, 0.3) 0%, rgba(108, 141, 255, 0.3) 100%);
			cursor: pointer;
			font-weight: normal;
			color: rgba(116, 148, 255, 0.6);
			line-height: 19px;
			align-items: center;
			justify-content: center;
			&:nth-child(2) {
				border-radius: 0px 19px 19px 0px;
			}
			&:nth-child(1) {
				border-radius: 19px  0px 0px 19px;
				
			}
			&.active {
				background: linear-gradient(120deg, #A5C1FF 0%, #6C8DFF 100%);
				color: #fff;
			}
		}
	}
	.page-header {
		display: flex;
		justify-content: space-between;
		background: #3473FF !important;
		.header-left {
			display: flex;
			align-items: center;
			cursor: pointer;

			.header-navs {
				font-size: 14px;
				display: flex;
				margin-right: 40px;
				position: relative;
				align-items: center;
				height: 50px;

				// &:before {
				// 	    position: absolute;
				// 	    height: 14px;
				// 	    background-color: #74798c;
				// 	    right: -5px;
				// 	    width: 1px;
				// 	    content: "";
				// 	    opacity: 0.5;
				// 	    top: 50%;
				// 	    transform: translateY(-50%);
				// }
				div {
					margin-right: 40px;

					&:hover {
						color: #6C8DFF;
						opacity: 0.8;
					}

					&.active {
						font-weight: 700;
						color: #6C8DFF;
						font-size: 16px;
						position: relative;

						&:after {
							width: 26px;
							height: 3px;
							content: "";
							background: #6C8DFF;
							border-radius: 2px;
							position: absolute;
							bottom: -20px;
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}
			}
		}

		.header-right {
			display: flex;
			align-items: center;
			.user-info {
				display: flex;
				align-items: center;
				img {
					width: 32px;
					height: 32px;
					border-radius: 100px;
					overflow: hidden;
					border: 1px solid #ccc;
					margin-right: 10px;
				}
				p {
					font-size: 14px;
						font-weight: normal;
						font-stretch: normal;
						letter-spacing: 0px;
						color: #333;
				}
			}
			.setting {
				margin-left: 25px;
				cursor: pointer;
				i {
					font-size: 24px;
				}
			}
		}
	}

	.silble-hide-btn {
		height: 50px;
		display: flex;
		align-items: center;
		cursor: pointer;

		img {
			width: 19px;
			height: 15px;
			margin-right: 10px;
		}

		.show-hide-title {
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0px;
			color: #333;
		}
	}

	.header-logo {
		color: #333;
		height: 60px;
		background-color: #3473FF !important;
		border-bottom: 1px solid #eaecef;
		// background-image: url(../../assets/logo.png);
		background-color: red;
		background-size: 80%;
		background-repeat: no-repeat;
		background-position: center;
		font-size: 20px;
		display: flex;
		align-items: center;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 72px;
		position: relative;
		img {
			position: absolute;
			right: 0px;
			top: 50%;
			width: 24px;
			cursor: pointer;
			height: 20px;
			transform: translate(0,-50%);
		}
	}

	.el-header {
		color: #333;
		height: 60px;
		background-color: #ffffff;
		border-bottom: 1px solid #eaecef;
	}

	.el-aside {
		color: #333;
	}

	.elmenuleft {
		height: calc(100% - 61px);
		overflow: auto;
		box-sizing: border-box;
		
		.el-menu-item .el-menu-item-level i {
			margin-right: 10px !important;
		}
		
		.el-menu-item-level {
			    height: 50px;
			    border-radius: 4px;
			    display: flex;
			    align-items: center;
			    padding: 0px !important;
			    padding-left: 10px !important;
			    font-size: 14px;
			    color: #333;
			    transition: all 0s !important;
		}

		&>.el-menu-item {
			font-size: 14px;
			&.is-active {
				&:after {
					width: 4px;
					height: 56px;
					background: #3473FF;
					border-radius: 2px;
					position: absolute;
					left: 0px;
					top: 0px;
					content: "";
				}
				.el-menu-item-level {
					i {
						font-weight: 700;
					}
					// background: linear-gradient(120deg, #A5C1FF 0%, #6C8DFF 100%);
					// color: #fff;
					font-weight: 700;
					color: #3473FF;
				}
			}
			&:hover {
				background: linear-gradient(90deg, rgba(52, 115, 255, 0.1) 0%, rgba(52, 115, 255, 0.05) 100%) !important;
			}
			.el-menu-item-level {
				// &:hover {
				// 	    background-color: #f0f4ff;
				// }
			}
		}

		.el-submenu__title {
			margin: 0px 20px;
			height: 50px;
			border-radius: 4px;
			display: flex;
			align-items: center;
			padding: 0px !important;
			padding-left: 10px !important;
			font-size: 14px;
			color: #333;
			transition: all 0s !important;
			i {
				margin-right: 10px !important;
			}
			&:hover {
				background: transparent !important;
			}
		}

		.el-menu-item-group__title {
			padding-top: 14px;
			padding-bottom: 4px;
		}
		&>.el-submenu {
			&:hover {
				background: linear-gradient(90deg, rgba(52, 115, 255, 0.1) 0%, rgba(52, 115, 255, 0.05) 100%) !important;
			}
		}
		&>.el-submenu.is-active {
			background: linear-gradient(90deg, rgba(52, 115, 255, 0.1) 0%, rgba(52, 115, 255, 0.05) 100%) !important;
			position: relative;
			
			
			
			&:after {
				width: 4px;
				height: 50px;
				background: #3473FF;
				border-radius: 2px;
				position: absolute;
				left: 0px;
				top: 0px;
				content: "";
			}
			.el-menu-item.is-active {
				background: transparent !important;
				font-weight: 700;
				color: #3473FF;
			}
			i {
				font-weight: 700;
				color: #3473FF;
			}

			.el-submenu__title {
				font-weight: 700;
				color: #3473FF;

				.el-submenu__icon-arrow {
					font-weight: 700;
					color: #3473FF;
				}
			}
		}

		.el-menu-item:hover {
			background-color: #fff;
		}

		.el-menu-item.is-active {
			background-color: #fff;
		}
		.el-menu {
			padding-top: 5px;
		}
		.el-menu-item-div {
			display: flex;
			align-items: center;

			&>span {
				    width: 6px;
				    height: 6px;
				    border: solid 2px #333;
				    margin-left: 8px;
				    margin-right: 9px;
				    border-radius: 100px;
					opacity: 0;
			}
			font-size: 14px;
		}
		.el-menu-item.is-active .el-menu-item-div{
			span {
				background-color:#6C8DFF;
				border: solid 2px #6C8DFF;
				opacity: 0;
			}
			color: #6C8DFF;
		}
	}

	.main-box {
		height: 100%;
		background-color: #fff;
		overflow: auto;
		border-radius: 5px;
	}
</style>
