<template>
	<div class="ele-div-box">
		<slot></slot>
	</div>
</template>

<script>
export default {
  name: "EleDiv",
}
</script>

<style lang="less" scoped>
	.ele-div-box {
		background: #fff;
		border-radius: 4px;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
	}
</style>
