import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false


// 图表
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 点击按钮样式 指令
import Ripple from 'vue-ripple-directive'
Vue.directive('ripple', Ripple);
Ripple.color = 'rgba(0, 0, 0, 0.2)'; //自定义水波纹颜色

// 饿了么ui库
import ElementUI from 'element-ui';
import './theme/index.css'
Vue.use(ElementUI);

// 顶部进度条
import NProgress from 'nprogress' 
import 'nprogress/nprogress.css'
NProgress.configure({showSpinner: false});

// 扩展组件
import EleDataTable from '@/components/EleDataTable'  // 数据表格
import EleDataFilter from '@/components/EleDataFilter'  // 数据过滤
import EleEmpty from '@/components/EleEmpty'  // 空数据
import EleDiv from '@/components/EleDiv'  // div样式
import ElePopConfirm from '@/components/ElePopConfirm'  // div样式

/* 全局注册常用组件 */
Vue.component(EleDataTable.name, EleDataTable);
Vue.component(EleDataFilter.name, EleDataFilter);
Vue.component(EleEmpty.name, EleEmpty);
Vue.component(EleDiv.name, EleDiv);
Vue.component(ElePopConfirm.name, ElePopConfirm);

// 设置指令
import webInstruction from './instruction/index.js';
Vue.use(webInstruction);

// axios
import './config/axios.js'

// 设置全局
import setting from './config/setting.js'
Vue.prototype.$_W = setting;
// 存储省市区
window.AreaDataTree = null;
window.AreaDataList = null;

// 获取文件图标  return：class
Vue.prototype.getFileImg = function (name) {
	if (name.indexOf('.doc') >= 0 || name.indexOf('.DOC') >= 0 || name.indexOf('.word') >= 0 || name.indexOf('.Word') >= 0) {
		return 'file-word'
	}
	if (name.indexOf('.PPT') >= 0 || name.indexOf('.ppt') >= 0) {
		return 'file-ppt'
	}
	if (name.indexOf('.PDF') >= 0 || name.indexOf('.pdf') >= 0) {
		return 'file-pdf'
	}
	if (name.indexOf('.MP3') >= 0 || name.indexOf('.mp3') >= 0) {
		return 'file-mp3'
	}
}
Vue.prototype.getFileAccept = '.doc,.DOC,.docx,.DOCX,.Word,.word,.ppt,.pptx,.PPT,.PPTX,.PDF,.pdf,.mp3,.MP3'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')




