<script type="text/javascript">
export default {
  name: 'ElePopConfirm',
  props: {
    title: {
      type: String,
      default: '你确认要删除数据吗？'
    },
    confirmButtonText: {
      type: String,
      default: '确定'
    },
    cancelButtonText: {
      type: String,
      default: '取消'
    },
    width: {
      type: [String, Number],
      default: '30%'
    },
    top: {
      type: [String, Number],
      default: '40vh'
    },
    disabled: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
	// 提示类型
	isType: {
      type: String,
      default: 'tips'
    },
	// 请求数据
	httpData: {
		type: Object,
		default: function () {
			// {
			// 	url: '' , //请求url
			//  method : 'get', //请求方式 默认get
			// 	data: {}, // 请求数据
			// }
		  return {};
		}
	}
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
	choose () {
		
	},
    handleDialog() {
      if (this.disabled) return;
      this.visible = true;
    },
    /**
     * 获取商品信息
     * @return {[type]} [description]
     */
    handleConfirm() {
      // 如果是删掉
	  if (this.isType == 'delete') {
		this.$http.request({
			url: this.httpData.url,
			method: this.httpData.method || 'get',
			data: this.httpData.data || {},
		}).then((res) => {
			if (res.code == 0) {
				this.visible = false;
				this.$emit('confirm', 1);
				this.$message({
				    message: '删除成功',
				    type: 'success'
				});
			}
		})
	  } else if (this.isType == 'deletePost') {
		console.log(this.httpData.data, 'this.httpData.datathis.httpData.data')
		this.$http.request({
			url: this.httpData.url,
			method: this.httpData.method || 'post',
			data: this.httpData.data || {},
		}).then((res) => {
			if (res.code == 200) {
				this.visible = false;
				this.$emit('confirm', 1);
				this.$message({
				    message: '删除成功',
				    type: 'success'
				});
			}
		})
	  } else if (this.isType == 'updateFile') {
		this.$http.request({
		  	url: this.httpData.url,
		  	method: 'post',
		  	data: {
				...this.httpData.data,
			}
		  }).then((res) => {
		  	if (res.code == 0) {
		  		this.visible = false;
		  		this.$emit('confirm', 1);
		  		this.$message({
		  		    message: this.httpData.mesg || '更新成功',
		  		    type: 'success'
		  		});
		  	}
		})
	  } else {
		this.visible = false;
		this.$emit('confirm');  
	  }
    },
    /**
     * 获取分组信息
     * @return {[type]} [description]
     */
    handleCancel() {
      this.visible = false;
      this.$emit('cancel');
    }
  },
  render() {
    let { handleConfirm, handleDialog, handleCancel, confirmButtonText, cancelButtonText } = this;
    let dialogData = {
      props: {
        title: this.title,
        visible: this.visible,
        width: this.width,
        center: true,
        top: this.top,
        'modal-append-to-body': false,
        'before-close': handleCancel
      }
    };
	let buttonType = 'primary'
	if (this.isType === 'delete' || this.isType === 'deletePost' || this.isType === 'updateFile') {
		buttonType = 'danger'
	}
    //判断插槽是否存在DOM结构，如果存在则获取后放入upload目录
    const trigger = this.$slots.trigger || this.$slots.default;
    return (
      <div class="le-popconfirm">
        <span on-click={handleDialog}>{trigger}</span>
        <el-dialog {...dialogData} choose="choose">
          <span slot="footer" class="dialog-footer">
            <el-button on-click={handleCancel}>{cancelButtonText}</el-button>
            <el-button type={buttonType} on-click={handleConfirm}>
              {confirmButtonText}
            </el-button>
          </span>
        </el-dialog>
      </div>
    );
  }
};
</script>
<style scoped type="text/css" lang="less">
@import './popconfirm.less';
</style>
