<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	import Layout from "@/components/Layout/Layout.vue";

	export default {
		name: "App",
		data() {
			return {};
		},
		components: {
			Layout,
		},
		mounted() {
			if (localStorage.getItem('Authorization') && localStorage.getItem('authority')) {
				let tt = JSON.parse(localStorage.getItem('authority'))
				if (!tt.includes('1')) {
					let a = [0, '/web/home/index', '/web/mechanism/list', '/web/node/list', '/web/device/list', '/web/trade/details-list', '/web/trade/details-list', '/web/trade/summary-list', '/web/system/config', '/web/system/config', '/system/maintain-mec', '/web/system/admins', '/web/system/role']
					this.$router.push(a[tt[0]])
				} else {
					this.$router.push({
						path: '/'
					})
				}
			}
			this.$http.request({
				url: '/api.admin.config.list/',
				method: 'post',
				data: {},
			}).then((res) => {
				localStorage.setItem('confing', JSON.stringify(res.data))
				localStorage.setItem('sys_name', res.data.sys_name)
				document.title= res.data.sys_name;
			})
		}
	};
</script>

<style lang="less">
	* {
		margin: 0px;
		padding: 0px;
		list-style: none;
		box-sizing: border-box;
		
	}
	div {
		color: #333;
	}
	p {
		color: #333;
	}
	
	.ddde .el-input__inner{
		    background: #F6F8F9;
		    border-radius: 5px;
			height: 54px;
			border: none !important;
	}
	
	.el-menu--collapse {
		.el-menu-item-level {
			padding-left: 0px !important;
			    color: #fff;
			i {
				padding-left: 3px !important;
				margin-right: 50px !important;
			}
		}
		.el-submenu__title {
			padding-left: 0px !important;
			    color: #fff;
		}
		.elmenuleft > .el-submenu:hover {
			color: rgba(52, 115, 255, 0.00);
		}
		.el-submenu__title i{
			padding-left: 3px !important;
			margin-right: 50px !important;
		}
		.el-menu-item.is-active .el-menu-item-level {
			font-weight: 700;
			color: rgba(52, 115, 255, 0.00);
			i {
				color: #3473FF;
				margin-right: 50px !important;
			}
		}
	}
	

	// 顶部进度条
	#nprogress .bar {
		background: #3473FF !important; //自定义颜色
	}

	.el-pagination.is-background .el-pager li:not(.disabled).active {
		color: #3473FF !important;
	}
	
	.el-checkbox__input.is-checked .el-checkbox__inner {
		    background-color: #3473FF !important;
		    border-color: #3473FF !important;
	}
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		background-color: #3473FF !important;
		border-color: #3473FF !important;
	}
	.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
		background-color: rgba(52, 115, 255, 0.13) !important;
	}
	.el-tree-node {
		margin-bottom: 4px !important;
	}
	.el-tree-node__content {
		margin-bottom: 4px !important;
	}

	// 滚动条
	.scrollbar-css::-webkit-scrollbar {
		/*滚动条整体样式*/
		width: 2px;
		/*高宽分别对应横竖滚动条的尺寸*/
		height: 1px;
	}
	.scrollbar-css::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 2px;
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		background: rgba(108, 141, 255, 0.4);
	}
	.scrollbar-css::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 2px;
		background: #ededed;
	}
	#content-main::-webkit-scrollbar {
		/*滚动条整体样式*/
		width: 6px;
		/*高宽分别对应横竖滚动条的尺寸*/
		height: 1px;
	}
	#content-main::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 2px;
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.6);
		background: rgba(108, 141, 255,1);
	}
	#content-main::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.6);
		border-radius: 2px;
		background: #ededed;
	}
	
	// 底部20
	.margin-bottom-20 {
		margin-bottom: 20px;
	}
	
	// 表格头部
	.el-table th.el-table__cell {
		background: #F6F6F6 !important;
		padding: 18px 0 !important;
	}
	.el-table--medium .el-table__cell {
	    padding: 13px 0 !important;
	}
	.el-table__body-wrapper .el-table--medium .el-table__cell {
		    padding: 14px 0 !important;
	}
	.el-table th.el-table__cell .el-checkbox{
		display: none;
	}
	
	.el-button--text {
		font-size: 14px !important;
		color: #3473FF !important;
	}
	
	// 弹窗修改
	.v-modal {
		background: #6e6e6e !important;
	}
	.el-dialog {
		border-radius: 4px !important;
	}
	
	// 区域选择 去掉lable
	.areabox .el-radio .el-radio__label {
		display: none;
	}
	.areabox .he-body__item-name {
		color: #333;
	}
	
	// 表单标题
	.formboxspan .le-form-item__label {
		color: #333 !important;
	}
	
	.el-form-item__label {
		color: #333 !important;
	}
	
	.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
		    background: #F6F6F6 !important;
	}
	
	.footer-btns {
		    position: fixed;
		    height: 50px;
		    margin: 0 -21px;
		    bottom: 0;
		    width: calc(100% - 220px);
		    background: #fff;
		    z-index: 100;
		    padding-top: 10px;
		    padding-left: 20px;
		    padding-right: 20px;
		    text-align: center;
			z-index: 310;
	}
	
	.he-dialog>div>div.el-dialog__header {
		padding-bottom: 13px !important;
		border-bottom: 1px solid #EEEEEE !important;
		text-align: center;
	}
	
	.el-button--default:focus, .el-button--default:hover {
		    color: rgba(52, 115, 255,1) !important;
		    border-color: rgba(52, 115, 255, 0.1) !important;
		    background-color: rgba(52, 115, 255, 0.1) !important;
	}
	
	// 按钮颜色
	body .el-button--primary {
		background-color: #3473FF !important;
	}
	body .el-button--primary:hover {
		background-color: #3473FF !important;
	}
	body .el-button--danger {
		background: #FE0000 !important;
	}
	body .el-button--danger:hover {
		background: #FE0000 !important;
	}
	
	.he-dialog>div>div.el-dialog__footer {
		    margin-top: -20px !important;
		    padding-bottom: 40px !important;
			    text-align: center !important;
	}
	
	.el-pagination.is-background .el-pager li:not(.disabled).active {
		background: #FFFFFF !important;
		border: 1px solid #4C84FF !important;
		border-radius: 4px;
		color: #4C84FF;
	}
	
	.el-pager li {
		background-color: #fff !important;
	}
	
	.el-radio__input.is-checked+.el-radio__label {
		color: #3473FF !important;
	}
	.el-radio__input.is-checked+.el-radio__label {
		color: #3473FF !important;
	}
	.el-select-dropdown__item.selected {
		color: #3473FF !important;
	}
	.el-radio__input.is-checked .el-radio__inner {
		    border-color: #6c8dff !important;
		    background: #6c8dff !important;
	}
	
	.el-pagination button {
		background: #FFFFFF !important;
		border: 1px solid #E1E1E1 !important;
		border-radius: 4px !important;
	}
	
	// 按钮圆
	.btns-rad100 .el-button-radio{
		border-radius: 100px !important;
	}
	.el-button-radio-btn{
		border-radius: 100px !important;
	}
	.file-span-img {
		    width: 21px;
		    height: 25px;
		    display: block;
		    background-size: cover;
		    margin-right: 6px;
			min-width: 21px;
	}
	
	// 上传文件
	.update-box-page .el-upload {
		width: 100%;
	}
	.update-box-page .el-upload-dragger {
		    width: 100%;
		    height: 120px;
	}
	.update-box-page .el-upload-dragger .el-icon-upload {
		margin-top: 20px;
	}
	
	// 地区表
	.areatreebox .el-table td.el-table__cell {
		    padding: 2px 0 !important;
	}
	.update-box-page .el-upload-dragger {
		height: auto !important;
	}
	
	.tree-zdy-css {
		position: relative;
		padding-left: 6px;
		&:after {
			position: absolute;
			    content: "";
			    height: 100%;
			    width: 1px;
			    background: #EEEEEE;
			    top: 5px;
			    left: 1px;
			
		}
		&>.el-tree {
			&>.el-tree-node {
				position: relative;
				&:after {
					  content: "";
					  position: absolute;
					  left: -6px;
					  top: 4px;
					width: 3px;
					height: 18px;
					z-index: 111;
					background: linear-gradient(120deg, #A5C1FF 0%, #6C8DFF 100%);
				}
			}
		}
		.el-tree-node__expand-icon:before {
			width: 14px !important;
			height: 14px !important;
			content: '';
			display: block;
			background-image: url(assets/zkzk1.png) !important;
			transform: rotate(0deg) !important;
			
		}
		.el-tree-node__expand-icon.expanded {
			transform: rotate(0deg) !important;
		}
		.el-tree-node__expand-icon.expanded:before {
			background-image: url(assets/zkzk.jpg) !important;
			
		}
		.el-tree-node__expand-icon.is-leaf {
			padding: 4px !important;
		}
		.el-tree-node__expand-icon.is-leaf:before {
			background:transparent !important;
			display: none;
		}
	}
	
	.tagt {
		   white-space: nowrap;
		    text-overflow: ellipsis;
		    overflow: hidden;
		    word-break: break-all;
	}
	
	.filter-data .el-form--inline .el-form-item {
		margin-right: 20px;
	}
	
	.elmenuleft .el-menu-item.is-active {
		    background: linear-gradient(90deg, rgba(52, 115, 255, 0.1) 0%, rgba(52, 115, 255, 0.05) 100%) !important;
	}
</style>
