<template>
	<div>
		<div class="box" :key="node_id" style="margin-bottom: 30px;">
			<div class="title-h2">
				<span>图文资讯</span>
				<el-button module="node" width="1000" title="添加资讯" size="mini" class="el-button-radio" action="getVideoList" v-popup.textAdd="{'node_id': node_id}" type="primary" icon="el-icon-circle-plus-outline">添加资讯</el-button>
			</div>
			<div class="video-list">
				<div class="video-item" v-for="(item, index) in textList">
					<div class="im">
						<img :src="item.thumbnail" alt="">
					</div>
					<div class="tabe" style="height: auto;display: block;padding-bottom: 10px;">
						<div style="padding-top: 10px;margin-bottom: 10px;line-height: 20px;">{{item.title}}</div>
						<div style="display: flex;justify-content: space-between;align-items: center;">
							<div>
								<el-tag type="success" v-if="item.is_used == 1">启动</el-tag>
								<el-tag type="warning" v-if="item.is_used == 2">暂停</el-tag>
							</div>
							<div>
								<el-button module="node" width="1000" title="编辑资讯" :key="item.keyId" size="mini" class="el-button-radio" action="getVideoList" v-popup.textAdd="{...item, 'node_id': node_id}" type="primary" >编辑</el-button>
								<ele-pop-confirm width="424px" @confirm="getVideoList" :key="item.cont_id"
									:httpData="{'url': '/api.admin.content.del/', 'data': {'cont_id': item.cont_id}}" isType="deletePost"
									title="资讯将被删除且无法撤销，确定删除？">
									<el-button style="margin-left: 6px;" class="el-button-radio"type="danger"
										size="mini">删除</el-button>
								</ele-pop-confirm>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style="margin-top: 20px;margin-left: -10px;" v-if="text_total_page != 0">
				<el-pagination
				  background
				  layout="prev, pager, next"
				  :total="text_total_page" @current-change="">
				</el-pagination>
			</div>
		</div>
		<div class="box" :key="node_id" >
			<div class="title-h2">
				<span style="width: 70px;">多媒体</span>
				<el-button module="node" width="460" title="添加多媒体" size="mini" class="el-button-radio" action="getVideoList" v-popup.updateFile="{'node_id': node_id}" type="primary" icon="el-icon-circle-plus-outline">添加多媒体</el-button>
			</div>
			<div class="video-list">
				<div class="video-item" v-for="(item, index) in videoList">
					<div class="im">
						<img :src="item.url" v-if="item.type == 1" alt="">
						<video :src="item.url"  controls="controls"  v-if="item.type == 2"></video>
					</div>
					<div class="tabe">
						<div>
							<el-tag type="success" v-if="item.status == 1">启动</el-tag>
							<el-tag type="warning" v-if="item.status == 2">暂停</el-tag>
						</div>
						<div>
							<el-button module="node" width="460" title="编辑多媒体" :key="item.keyId"  size="mini" class="el-button-radio" action="getVideoList" v-popup.updateFile="{...item, 'node_id': node_id}" type="primary" >编辑</el-button>
							<ele-pop-confirm width="424px" @confirm="getVideoList" :key="item.media_id"
								:httpData="{'url': '/api.admin.media.del/', 'data': {'media_id': item.media_id}}" isType="deletePost"
								title="多媒体将被删除且无法撤销，确定删除？">
								<el-button style="margin-left: 6px;" class="el-button-radio"type="danger"
									size="mini">删除</el-button>
							</ele-pop-confirm>
						</div>
					</div>
				</div>
			</div>
			<div style="margin-top: 20px;margin-left: -10px;">
				<el-pagination
				  background
				  layout="prev, pager, next"
				  :total="total_page" @current-change="">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			value: {
				type: [Object, Array]
			}
		},
		data() {
			return {
				node_id: 0,
				videoList: [],
				textList: [],
				text_total_page: 0,
				total_page: 0
			};
		},
		methods: {
			affirm: function(e) {
				let {
					handleClose
				} = e;
				console.log(this.form)
				this.$refs['form'].validate(valid => {
					if (valid) {
						this.$http.request({
							url: '/api.admin.node.add/',
							method: 'post',
							data: this.form,
						}).then((res) => {
							if (res.code == 200) {
								handleClose(res.data);
								this.$message({
									message: this.form.corp_id ? '编辑网点信息成功' : '添加网点成功',
									type: 'success'
								});
							}
						})
					} else {
				  return false;
					}
				});
			},
			getVideoList () {
				this.$http.request({
					url: '/api.admin.media.list/',
					method: 'post',
					data: {
						page: 1,
						node_id: this.node_id,
					},
				}).then((res) => {
					if (res.code == 200) {
						this.videoList = res.data.list.map((v) => {
							return {
								...v,
								keyId: 'e' + Math.random() * 10000
							}
						})
						this.total_page = res.data.total_page * 10
						console.log(this.videoList, 'this.videoList')
					}
				})
				
				this.$http.request({
					url: '/api.admin.content.list/',
					method: 'post',
					data: {
						page: 1,
						node_id: this.node_id,
					},
				}).then((res) => {
					if (res.code == 200) {
						this.textList = res.data.list.map((v) => {
							return {
								...v,
								keyId: 'd' + Math.random() * 10000
							}
						})
						this.text_total_page = res.data.total_page * 10
					}
				})
			},
		},
		mounted() {
			if (Object.prototype.toString.call(this.value) === '[object Object]') {
				this.node_id = this.value.node_id
				this.getVideoList()
			}
		}
	};
</script>

<style lang="less" scoped>
	.title-h2 {
		font-weight: 700;
		display: flex;
		align-items: center;
		&>span {
			color: #3473FF;
			font-size: 18px;
			margin-right: 16px;
		}
		&:before {
			margin-right: 8px;
			width: 3px;
			height: 18px;
			background-color: #3473FF;
			display: inline-block;
			content: "";
		}
	}
	.video-list {
		display: flex;
		margin-top: 20px;
		.tabe {
			height: 50px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border: 1px solid #dbdbdb;
			    padding-left: 10px;
			    padding-right: 5px;
		}
		.video-item {
			margin-right: 20px;
			width: 200px;
		}
		.im {
			img {
				width: 200px;
				display: block;
				background-color: #6e6e6e;
				height: 120px;
			}
			video {
				display: block;
				background-color: #6e6e6e;
				width: 200px;
				height: 120px;
			}
		}
	}
</style>
