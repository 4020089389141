/*
 * @Author: qinuoyun
 * @Date:   2020-09-08 10:12:19
 * @Last Modified by:   qinuoyun
 * @Last Modified time: 2021-06-21 14:40:12
 */
import webInstruction from '@/instruction/index.js';
//加载页面
import nodeAdd from './components/node-add';
import detailInfo from './components/detail-info';
import updateFile from './components/update-file';
import textAdd from './components/text-add';
import infoEdit from './components/info-edit';
import desEdit from './components/des-edit';
//初始化变量
let modules = {};
modules.install = function () {
  //添加设置界面
  webInstruction.component('node', 'nodeAdd', nodeAdd);
  webInstruction.component('node', 'detailInfo', detailInfo);
  webInstruction.component('node', 'updateFile', updateFile);
  webInstruction.component('node', 'textAdd', textAdd);
  webInstruction.component('node', 'infoEdit', infoEdit);
  webInstruction.component('node', 'desEdit', desEdit);
};
export default modules;
