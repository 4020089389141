<template>
	<el-form class="le-matter" v-loading="loading" label-width="80px" ref="form" :model="form" :rules="rules" @submit.native.prevent>
		<el-form-item prop="corp_id" v-if="editype == 'none' || editype == 'info'">
			<span slot="label" class="le-form-item__label">所属机构</span>
			<el-select v-model="form.corp_id" @change="selectCorp" placeholder="请选择所属机构" style="width: 100%;">
				<el-option v-for="item in corpList" :key="item.value" :label="item.corp_name" :value="item.corp_id">
				</el-option>
			</el-select>
		</el-form-item>
		<el-form-item prop="role_id" v-if="editype == 'none' || editype == 'info'">
			<span slot="label" class="le-form-item__label">所属角色</span>
			<el-select v-model="form.role_id" @change="selectCorp" placeholder="请选择所属角色" style="width: 100%;">
				<el-option v-for="item in roleList" :key="item.value" :label="item.role_name" :value="item.role_id">
				</el-option>
			</el-select>
		</el-form-item>
		<el-form-item prop="admin_name" v-if="editype == 'none' || editype == 'info'">
			<span slot="label" class="le-form-item__label">账号</span>
			<el-input v-model="form.admin_name" placeholder="请输入账号"></el-input>
		</el-form-item>
		<el-form-item prop="real_name" v-if="editype == 'none' || editype == 'info'">
			<span slot="label" class="le-form-item__label">姓名</span>
			<el-input v-model="form.real_name" placeholder="请输入姓名"></el-input>
		</el-form-item>
		<el-form-item prop="mobile" v-if="editype == 'none' || editype == 'info'">
			<span slot="label" class="le-form-item__label">联系方式</span>
			<el-input v-model="form.mobile" placeholder="请输入联系方式"></el-input>
		</el-form-item>
		<el-form-item prop="password" v-if="editype == 'none' || editype == 'password'">
			<span slot="label" class="le-form-item__label">密码</span>
			<el-input v-model="form.password" type="password" placeholder="请输入密码"></el-input>
		</el-form-item>
		<el-form-item prop="npassword" v-if="editype == 'none' || editype == 'password'">
			<span slot="label" class="le-form-item__label">确认密码</span>
			<el-input v-model="form.npassword" type="password" placeholder="请输入确认密码"></el-input>
		</el-form-item>
		<el-form-item prop="is_lock" v-if="editype == 'none' || editype == 'info'">
			<span slot="label" class="le-form-item__label">状态</span>
			<el-radio-group v-model="form.is_lock">
			    <el-radio :label="0">正常</el-radio>
			    <el-radio :label="1">锁定</el-radio>
			  </el-radio-group>
		</el-form-item>
	</el-form>
</template>

<script>
	export default {
		props: {
			value: {
				type: [Object, Array]
			}
		},
		data() {
			return {
				form: {
					corp_id: null,//	是	int	机构ID
					role_id: null,//	是	int	角色ID
					admin_name: '',//	是	string	账号
					real_name: '',//	是	string	姓名
					mobile: '',//	是	string	联系方式
					password: '',//	是	string	密码
					npassword: '',//	是	string	确认密码
					is_lock: 0,//	是	int	1锁定2启用
				},
				rules: {
					corp_id: [{
						required: true,
						message: '请选择所属机构',
						trigger: 'blur'
					}],
					role_id: [{
						required: true,
						message: '请选择所属角色',
						trigger: 'blur'
					}],
					admin_name: [{
						required: true,
						message: '请输入账号',
						trigger: 'blur'
					}],
					real_name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					mobile: [{
						required: true,
						message: '请输入联系方式',
						trigger: 'blur'
					}, {
						pattern: /^((0\d{2,3}-\d{7,8})|(1[3657894]\d{9}))$/,
						message: "请输入合法手机号/电话号",
						trigger: "blur"
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}, { min: 6, message: '密码长度至少6位数', trigger: 'blur' }],
					npassword: [{
						required: true,
						message: '请输入确认密码',
						trigger: 'blur'
					}, { min: 6, message: '密码长度至少6位数', trigger: 'blur' }],
				},
				corpList: [],
				roleList: [],
				loading: false,
				editype: 'none'
			};
		},
		methods: {
			affirm: function(e) {
				let {
					handleClose
				} = e;
				this.$refs['form'].validate(valid => {
					if (valid) {
						if (this.editype == 'info' || this.editype == 'none') {
							let url = '/api.admin.admin.add/'
							if (this.form.admin_id) {
								url = '/api.admin.admin.edit/'
							}
							this.$http.request({
								url: url,
								method: 'post',
								data: this.form,
							}).then((res) => {
								if (res.code == 200) {
									handleClose(res.data);
									this.$message({
										message: this.form.admin_id ? '编辑管理员信息成功' : '添加管理员成功',
										type: 'success'
									});
								}
							})
						} else if(this.editype == 'password'){
							this.$http.request({
								url: '/api.admin.admin.updatepwd/',
								method: 'post',
								data: {
									admin_id: this.form.admin_id,
									password: this.form.password,//	是	string	密码
									npassword: this.form.npassword,//	是	string	确认密码
								},
							}).then((res) => {
								if (res.code == 200) {
									handleClose(res.data);
									this.$message({
										message: '重置管理员密码成功',
										type: 'success'
									});
								}
							})
						}
					} else {
				  return false;
					}
				});
			},
			getCorpList () {
				this.$http.request({
					url: '/api.admin.corp.treelist/',
					method: 'post',
					data: {},
				}).then((res) => {
					this.corpList = res.data
				})
			},
			getRoleList () {
				this.$http.request({
					url: '/api.admin.role.treelist/',
					method: 'post',
					data: {},
				}).then((res) => {
					this.roleList = res.data
				})
			},
		},
		mounted() {
			this.getCorpList()
			this.getRoleList()
			if (Object.prototype.toString.call(this.value) === '[object Object]') {
				this.editype = this.value.editype
				console.log(this.value, 'this.valuethis.value')
				this.loading = true
				this.$http.request({
					url: '/api.admin.admin.detail/',
					method: 'post',
					data: {
						admin_id: this.value.admin_id,
					},
				}).then((res) => {
					
					this.form = {
						corp_id: res.data.corp_id,//	是	int	机构ID
						role_id: res.data.role_id,//	是	int	角色ID
						admin_name: res.data.admin_name,//	是	string	账号
						real_name: res.data.real_name,//	是	string	姓名
						mobile: res.data.mobile,//	是	string	联系方式
						password: res.data.password,//	是	string	密码
						npassword: res.data.npassword,//	是	string	确认密码
						is_lock: res.data.is_lock,//	是	int	1锁定2启用
						admin_id: res.data.admin_id
					}
					this.loading = false
				})
			} else {
				this.editype = 'none'
			}
		}
	};
</script>

<style>
</style>
