import Vue from 'vue';
import Router from 'vue-router';
import routes from './routers';
import NProgress from 'nprogress'
import setting from '../config/setting'

Vue.use(Router);

const router = new Router({
  routes,
  base: '/',
  mode: 'hash' //history || hash
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (setting.isWhite) {
	try {
	  let whiteList = [];
	  // 开启$Cloud
	  const $heshop = router.app.$heshop;
	  // 获取登录页面
	  const loginPage = setting.loginPath;
	  // 处理白名单列表
	  whiteList = setting.whiteList || [];
	  // 获取授权登录
	  let token = localStorage.getItem(setting.tokenHeaderName) || '';
	  //判断用户是否登陆
	  if (token) {
	    if (to.path == loginPage) {
	      next('/');
	    } else {
	      next();
	    }
	  } else {
	    //处理白名单配置
	    if (whiteList.includes(to.name)) {
	      // 在免登录白名单，直接进入
	      next();
	    } else {
	      let toPath = '/';
	      if (to.path !== loginPage) {
	        toPath = to.fullPath;
	        next({ path: loginPage, query: { redirect: toPath } });
	      } else {
	        next();
	      }
	    }
	  }
	} catch (error) {
	  console.error('err', error);
	}  
  } else {
	next();  
  }
});

router.afterEach(() => {
  setTimeout(() => {
  	NProgress.done();
  }, 150);
  if (document.getElementById("content-main")) {
	  document.getElementById("content-main").scrollTop = 0;
  }
});

export default router;
