import Layout from "@/components/Layout/Layout.vue";
import Main from "@/components/Layout/Main.vue";

export default {
	path: '/web',
	component: Layout,
	name: 'web',
	meta: {
		title: '智慧功德箱'
	},
	redirect: '/web/trade/details-list',
	children: [{
			path: '/web/home/index',
			meta: {
				keepAlive: true,
				title: '工作台',
				parentMenu: '/web',
				icon: 'el-icon-data-analysis',
				id: 1,
			},
			component: () => import( /* webpackChunkName: "channel/appletsInfo" */
				'../../views/web/home/index'),	
		},
		{
			path: '/web/device/list',
			meta: {
				keepAlive: true,
				title: '设备管理',
				parentMenu: '/web',
				icon: 'el-icon-mobile-phone',
				id: 4,
			},
			component: () => import( /* webpackChunkName: "channel/appletsInfo" */
				'../../views/web/device/list'),
		},{
			path: '/web/trade/details-list',
			meta: {
				keepAlive: true,
				title: '交易管理',
				parentMenu: '/web',
				icon: 'el-icon-bank-card',
				id: 5,
			},
			component: Main,
			children: [{
					path: '/web/trade/details-list',
					component: () => import( /* webpackChunkName: "channel/appletsInfo" */
						'../../views/web/trade/details-list'),
					meta: {
						title: '交易明细',
						parentMenu: '/web',
						id: 6,
					}
				},
				{
					path: '/web/trade/summary-list',
					component: () => import( /* webpackChunkName: "channel/appletsPay" */
						'../../views/web/trade/summary-list'),
					meta: {
						keepAlive: true,
						title: '交易汇总',
						parentMenu: '/web',
						id: 7,
					}
				}
			]
		},
		{
			path: '/web/node/list',
			meta: {
				keepAlive: true,
				title: '网点管理',
				parentMenu: '/web',
				icon: 'el-icon-discount',
				id: 3,
			},
			component: () => import( /* webpackChunkName: "channel/appletsInfo" */
				'../../views/web/node/list'),
		},
		{
			path: '/web/mechanism/list',
			meta: {
				keepAlive: true,
				title: '机构管理',
				parentMenu: '/web',
				icon: 'el-icon-school',
				id: 2,
			},
			component: () => import( /* webpackChunkName: "channel/appletsInfo" */
				'../../views/web/mechanism/list'),	
		},
		{
			path: '/web/system/config',
			meta: {
				keepAlive: true,
				title: '系统管理',
				parentMenu: '/web',
				icon: 'el-icon-set-up',
				id: 8,
			},
			component: Main,
			children: [
				{
					path: '/web/system/admins',
					component: () => import( /* webpackChunkName: "channel/appletsInfo" */
						'../../views/web/system/admins/list'),
					meta: {
						title: '管理员列表',
						parentMenu: '/web',
						id: 11,
					}
				},
				{
					path: '/web/system/role',
					component: () => import( /* webpackChunkName: "channel/appletsInfo" */
						'../../views/web/system/role/list'),
					meta: {
						title: '角色列表',
						parentMenu: '/web',
						id: 12,
					}
				},
				{
						path: '/web/system/maintain-mec',
						component: () => import( /* webpackChunkName: "channel/appletsInfo" */
							'../../views/web/system/maintain-mec/list'),
						meta: {
							title: '资料维护',
							parentMenu: '/web',
							id: 10,
						}
				},
				{
						path: '/web/system/dev',
						component: () => import( /* webpackChunkName: "channel/appletsInfo" */
							'../../views/web/system/dev/list'),
						meta: {
							title: '客户端管理',
							parentMenu: '/web',
							id: 13,
						}
				},
				{
						path: '/web/system/config',
						component: () => import( /* webpackChunkName: "channel/appletsInfo" */
							'../../views/web/system/config/list'),
						meta: {
							title: '参数配置',
							parentMenu: '/web',
							id: 9,
						}
				},
			]
		}
	]
};
