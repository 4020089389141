import './btnAuth';
import { install } from './install';
import utils from './utils/utils.js';
import cloud from './utils/cloud.js';
import Vue from 'vue';

var components = {};

export default class heshop {
  /**
   * 组建列表
   * @type {Object}
   */
  components = {};
  /**
   * 初始化安装器
   * @return {[type]} [description]
   */
  static install() {}

  /**
   * 插件处理
   * @param  {[type]} files [description]
   * @return {[type]}       [description]
   */
  static plugin(files) {
    this.files = files;
  }


  static component(id, name, component = '') {
    if (id == 'components') {
      Vue.component(name, component);
    } else {
      let key = id + '_' + name;
      if (id && name && component) {
        components[key] = component;
      }
      if (id && name && component == '') {
        return components[key] || false;
      }
    }
  }

  /**
   * 错误处理
   * @return {[type]} [description]
   */
  static error() {}

  /**
   * 数据库初始化，并取得数据库类实例
   * @access public
   * @param  mixed       config 连接配置
   * @param  bool|string name   连接标识 true 强制重新连接
   * @return Connection
   * @throws Error
   */
  static connect(config = {}) {
    try {
      if (config.AppID == '') {
        throw new Error('AppID不能为空');
      }
      if (config.AppSecret == '') {
        throw new Error('AppSecret不能为空');
      }
      if (config.AppName == '') {
        throw new Error('AppSecret不能为空');
      }
      if (config.AuthSign == '') {
        config.AuthSign = false;
      }
      if (config.AppURL == '') {
        throw new Error('AppURL不能为空');
      }
      if (!config.AppConfig) {
        config.AppConfig = {
          whiteList: null,
          autoToken: null,
          defaultRoute: null
        };
      }
      if (!this.instance) {
        this.instance = [];
      }
      if (this.instance.indexOf(config.AppID) == -1) {
        this.instance[config.AppID] = this.get_proxy(config);
      }
      this.config = config;
      return this.instance[config.AppID];
    } catch (error) {
      console.error('请求报错提示', error);
    }
  }
}

heshop.install = install;
heshop.version = '0.0.2';

if (utils.inBrowser() && window.Vue) {
  window.Vue.use(heshop);
}
