<template>
	<el-form class="le-matter" label-width="80px" ref="form" :model="form" :rules="rules" @submit.native.prevent>
		<el-form-item prop="corp_id">
			<span slot="label" class="le-form-item__label">所属机构</span>
			<el-select v-model="form.corp_id" placeholder="请选择所属机构" style="width: 100%;">
				<el-option v-for="item in corpList" :key="item.value" :label="item.corp_name" :value="item.corp_id">
				</el-option>
			</el-select>
		</el-form-item>
		<el-form-item prop="node_name">
			<span slot="label" class="le-form-item__label">网点名称</span>
			<el-input v-model="form.node_name" placeholder="请输入网点名称"></el-input>
		</el-form-item>
		<el-form-item prop="province">
			<span slot="label" class="le-form-item__label">所属地区</span>
			<area-select @getAara="getAara" :key="areaValue3.length" :area="areaValue3"></area-select>
		</el-form-item>
		<el-form-item prop="address">
			<span slot="label" class="le-form-item__label">详细地址</span>
			<el-input v-model="form.address" placeholder="请输入详细地址"></el-input>
		</el-form-item>
		<el-form-item prop="address">
			<span slot="label" class="le-form-item__label">二维码</span>
			<upload-image v-model="form.wechat" :limit="1"></upload-image>
		</el-form-item>
	</el-form>
</template>

<script>
	import AreaSelect from "@/components/City1/index.vue";
	import axios from 'axios';
	import OSS from "ali-oss";
	import uploadImage from "@/components/uploadImage.vue";
	function random_string(len) {
	  len = len || 32;
	  var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
	  var maxPos = chars.length;
	  var pwd = "";
	  for (let i = 0; i < len; i++) {
	    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
	  }
	  return pwd;
	}
	function getNowFormatDate() {
	      var date = new Date();
	      var seperator1 = "-";
	      var year = date.getFullYear();
	      var month = date.getMonth() + 1;
	      var strDate = date.getDate();
	      if (month >= 1 && month <= 9) {
	        month = "0" + month;
	      }
	      if (strDate >= 0 && strDate <= 9) {
	        strDate = "0" + strDate;
	      }
	      var currentdate = year + seperator1 + month + seperator1 + strDate;
	      return currentdate;
	}
	export default {
		props: {
			value: {
				type: [Object, Array]
			}
		},
		data() {
			return {
				form: {
					node_id: 0,
					corp_id: null,
					node_name: '',
					province: '',
					city: '',
					district: '',
					address: '',
					wachat:'',
				},
				rules: {
					node_name: [{
						required: true,
						message: '请输入网点名称',
						trigger: 'blur'
					}],
					address: [{
						required: true,
						message: '请输入详细地址',
						trigger: 'blur'
					}],
					province: [{
						required: true,
						message: '请选择地区',
						trigger: 'change'
					}],
					corp_id: [{
						required: true,
						message: '请选择所属机构',
						trigger: 'change'
					}],
					wechat: [{
						required: true,
						message: '请上传二维码图片',
						trigger: 'change'
					}],
				},
				areaValue3: [],
				corpList: []
			};
		},
		components: {
			AreaSelect,
			uploadImage
		},
		methods: {
			affirm: function(e) {
				let {
					handleClose
				} = e;
				this.$refs['form'].validate(valid => {
					if (valid) {
						this.$http.request({
							url: '/api.admin.node.add/',
							method: 'post',
							data: this.form,
						}).then((res) => {
							if (res.code == 200) {
								handleClose(res.data);
								this.$message({
									message: this.form.corp_id ? '编辑网点信息成功' : '添加网点成功',
									type: 'success'
								});
							}
						})
					} else {
				  return false;
					}
				});
			},
			getAara(data) {
				this.form.province = data[0]
				this.form.city = data[1]
				this.form.district = data[2]
			},
			getCorpList () {
				this.$http.request({
					url: '/api.admin.corp.treelist/',
					method: 'post',
					data: {},
				}).then((res) => {
					this.corpList = res.data
				})
			}
		},
		mounted() {
			this.getCorpList()
			if (Object.prototype.toString.call(this.value) === '[object Object]') {
				this.form = {
					node_id: this.value.node_id,
					corp_id: this.value.corp_id,
					node_name: this.value.node_name,
					province: this.value.province,
					city: this.value.city,
					district: this.value.district,
					address: this.value.address,
					wechat:this.value.wechat
				}
				this.areaValue3 = [this.form.province, this.form.city, this.form.district]
			}
		}
	};
</script>

<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 128px;
    height: 128px;
    line-height: 128px;
    text-align: center;
  }
  .avatar {
    width: 128px;
    height: 128px;
    display: block;
  }
</style>
